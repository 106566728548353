<template>
  <div>
    <b-sidebar
      id="sidebar-right-change-project"
      ref="sidebar_change_project"
      :visible="showOpenChangeProject"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-change-project', val)"
    >
      <template #default="{ hide }">
        <b-overlay
          :show="loader"
          rounded="xl"
          no-fade
        >
          <div :aria-hidden="loader ? 'true' : null">
            <div
              class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50 changeproject-header"
              style="height: 130px; background: #fff;"
            >
              <b-card
                no-body
                class="overflow-hidden w-100"
                style=""
              >
                <h3
                  class="mb-0 pb-0 pl-2 mt-3"
                  style="font-weight: 700; color: #181E38 !important;"
                >
                  Move site
                </h3>
                <header-sidebar :idsite-header.sync="idsite" />
              </b-card>
              <feather-icon
                class="cursor-pointer"
                icon="XIcon"
                size="20"
                @click="hide"
              />
            </div>
            <div
              class="b-sidebar-body mt-0"
              style="padding: 40px; padding-bottom: 60px;"
            >
              <h3
                class="mb-0 pb-0"
                style="font-weight: 700; color: #656B85 !important; font-size: 16px; padding-top: 0px;"
              >
                Current Project
              </h3>
              <hr>
              <span
                class="mr-50 mt-1"
                style="font-weight: 700; color: #181E38; font-size: 16px; display: flex; margin-top: 0px;"
              >
                <div
                  :style="`width: 24px; height: 24px; border-radius: 4px; background: ${idsite.color}; margin-top: 6px;`"
                  class="mr-50"
                />
                <span class="mt-50">{{ idsite.label }}</span>
              </span>
              <hr>
              <h3
                class="mb-0 pb-0 mt-3"
                style="font-weight: 700; color: #656B85 !important; font-size: 16px;"
              >
                Select destination project
              </h3>
              <div
                style="max-height: calc(100vh - 478px); overflow-y: auto;"
              >
                <b-list-group
                  v-for="(project, index) in allProject"
                  :key="index"
                  flush
                >
                  <b-list-group-item
                    class="cursor-pointer"
                    @click.stop="selectedMoveFunction(project.label)"
                  >
                    <span
                      class="mr-50 mt-1"
                      style="font-weight: 700; color: #181E38; font-size: 16px; display: flex; margin-top: 0px;"
                    >
                      <b-form-checkbox
                        @change="selectedMoveFunction(project.label)"
                        v-model="selectedMove"
                        :value="project.label"
                        class="custom-control-primary mt-25"
                        style="margin-right: 6px;"
                      />
                      <div
                        :style="`width: 16px; height: 16px; border-radius: 4px; background: ${project.color}; margin-top: 6px;`"
                        class="mr-50"
                        @click.stop="selectedMoveFunction(project.label)"
                      />
                      <span @click.stop="selectedMoveFunction(project.label)">{{ project.label }} <span style="color: #7838ff !important;" v-if="project.owner === 'shared'">(Shared access)</span></span>
                    </span>
                  </b-list-group-item>
                  <hr class="mt-0 mb-0">
                </b-list-group>
              </div>
              <b-button
                style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px;"
                class="mt-4"
                variant="primary"
                :disabled="loadingSubmit"
                @click="submitEditar"
              >
                <span
                  class="align-middle"
                  style="font-weight: 700; color: #FFFFFF;"
                >Move site</span>
              </b-button>
            </div>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BOverlay,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, auth, firebase } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BOverlay,
    ClaimOwnership,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenChangeProject',
    event: 'update:show-open-change-project',
  },
  props: ['idsite', 'showOpenChangeProject'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      selectedMove: '',
      loader: true,
      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      allProject: [],
      loadingSubmit: false,
      // {
      //     label: 'My sites',
      //     color: '#F0F1F7',
      //     uid: 'w_p'
      // },
      //   {
      //       label: 'Facade interactive sites',
      //       color: '#F39C19',
      //   },
      //   {
      //       label: "Jane's sites",
      //       color: '#1CA085',
      //   },
      // ]
    }
  },
  watch: {
    idsite(val) {
      // console.log(val)
    },
    showOpenChangeProject(val) {
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }

      this.loader = true
      const user = auth.currentUser
      if(user && val) {
        // console.log('first')
        this.userData = ''
        this.allProject = []
        db.collection('Users').doc(user.uid).get()
        .then(userDoc => {
          var userData = {}
          userData.uid = userDoc.data().uid
          userData.foto = userDoc.data().foto ? userDoc.data().foto : ''
          userData.name = userDoc.data().nombre ? userDoc.data().nombre : ''
          userData.status = userDoc.data().status ? userDoc.data().status : ''
          userData.email = userDoc.data().email ? userDoc.data().email : ''
          this.userData = userData
          db.collection('Users').doc(user.uid).collection('projects').get()
          .then(userDocProjects => {
            userDocProjects.forEach(eleUserProject => {
              var filterProj = this.allProject.filter(ele => ele.uid === eleUserProject.data().uid)
              if(!filterProj.length) {
                if(eleUserProject.data().id !== this.idsite.projectid) {
                  if(eleUserProject.data().name === 'My sites') {
                    this.allProject.unshift({
                      label: eleUserProject.data().name,
                      name: eleUserProject.data().name ? eleUserProject.data().name.toLowerCase() : '',
                      owner: 'me',
                      color: eleUserProject.data().color,
                      uid: eleUserProject.data().id,
                    })

                    var condicionParaOrdenarAlpha = (pageA, pageB) => {
                      if(pageA.name === 'my sites') {
                        return -1
                      }
                      if(pageB.name === 'my sites') {
                        return 1
                      }
                      
                      return pageA.name.toLowerCase().localeCompare(pageB.name.toLowerCase())
                    }
                    this.allProject.sort(condicionParaOrdenarAlpha)
                  } else {
                    this.allProject.push({
                      label: eleUserProject.data().name,
                      name: eleUserProject.data().name ? eleUserProject.data().name.toLowerCase() : '',
                      owner: 'me',
                      color: eleUserProject.data().color,
                      uid: eleUserProject.data().id,
                    })

                    var condicionParaOrdenarAlpha = (pageA, pageB) => {
                      if(pageA.name === 'my sites') {
                        return -1
                      }
                      if(pageB.name === 'my sites') {
                        return 1
                      }
                      
                      return pageA.name.toLowerCase().localeCompare(pageB.name.toLowerCase())
                    }
                    this.allProject.sort(condicionParaOrdenarAlpha)
                  }
                }
              }
            })
            this.loader = false
          })

          // this.sortProjects()
          this.selectedMove = this.idsite.label ? this.idsite.label : 'My sites'
        })
      }
    },
  },
  validations: {
  },
  methods: {
    sortProjects() {
      this.allProject.sort(function (a, b) {
        if(a.name > b.name) {
          return 1;
        }
        if(a.name < b.name) {
          return -1;
        }
        return 0;
      });

      setTimeout(() => {
        this.loader = false
      }, 1500)
    },
    submitEditar() {
      if(this.selectedMove) {
        let projectSelected = ''
        projectSelected = this.allProject.filter(proj => proj.label === this.selectedMove)
        if(projectSelected.length >= 1 && projectSelected[0].uid) {
          // console.log(projectSelected[0])
          const user = auth.currentUser
          this.loadingSubmit = true
          // update project user data
          db.collection('Users').doc(user.uid).collection('projects').doc(this.idsite.projectid).get()
          .then(userP => {
            db.collection('Users').doc(user.uid).collection('projects').doc(this.idsite.projectid).update({
              numberSites: userP.data().numberSites - 1
            })
          })

          // delete site on project
          db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).delete()

          // delete item in array projects sites
          db.collection('Sites').doc(this.idsite.id).update({
            projects: firebase.firestore.FieldValue.arrayRemove(this.idsite.projectid)
          })
          db.collection('Sites_cache').doc(this.idsite.id).update({
            projects: firebase.firestore.FieldValue.arrayRemove(this.idsite.projectid)
          })


          // set item in array projects sites
          db.collection('Sites').doc(this.idsite.id).update({
            projects: firebase.firestore.FieldValue.arrayUnion(projectSelected[0].uid)
          })
          db.collection('Sites_cache').doc(this.idsite.id).update({
            projects: firebase.firestore.FieldValue.arrayUnion(projectSelected[0].uid)
          })

          // set site in moved project
          db.collection('Sites').doc(this.idsite.id).get()
          .then(docSite => {
            db.collection('Projects').doc(projectSelected[0].uid).collection('sites').doc(docSite.id).set({
              SiteID: docSite.id,
              WebsiteURL: docSite.data().WebsiteURL,
              homepage: docSite.data().homepage,
              name: docSite.data().name,
              n: docSite.data().n,
              thumbnail: docSite.data().thumbnail,
              totalErrors: docSite.data().totalErrors,
              plan: docSite.data().plan ? docSite.data().plan : 'Free',
              paidRecurrence: docSite.data().paidRecurrence,
              claimed: docSite.data().claimed,
              owner: docSite.data().owner,
              status: 'active',
            })

            setTimeout(() => {
              this.$router.push({
                name: 'view-errors',
                params: {
                  id: this.idsite.id, imgSite: docSite.data().thumbnail, name: docSite.data().name ? docSite.data().name : docSite.data().homepage, url: docSite.data().homepage, project: projectSelected[0].uid, own: 'me'
                },
              })
            }, 2000)
          })

          // update project moved user data
          db.collection('Users').doc(user.uid).collection('projects').doc(projectSelected[0].uid).get()
          .then(userP => {
            db.collection('Users').doc(user.uid).collection('projects').doc(projectSelected[0].uid).update({
              numberSites: userP.data().numberSites + 1
            })
          })

          setTimeout(() => {
            db.collection('Users').doc(this.userData.uid).update({
              updated: Date.now(),
            })

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Site moved',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            this.loadingSubmit = false
            this.$refs.sidebar_change_project.hide()
          }, 1500)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No site chosen',
              icon: 'BellIcon',
              variant: 'warning',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No site chosen',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    selectedMoveFunction(payload) {
      this.selectedMove = payload
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-change-project {
    width: 90%;
    max-width: 593px !important;
}

@media (max-width: 768px) {
  .changeproject-header {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>
