<template>
  <div>
    <b-sidebar
      id="sidebar-right-reactivate-subscription"
      ref="sidebar_reactivate_subscription"
      :visible="showOpenReactivateSubscription"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-reactivate-subscription', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-50">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>
        <hr v-if="idsite.label">
        <b-button
          variant="flat-light"
          style="color: rgb(24, 30, 56); display: block; text-align: left; width: 40px; margin-left: 10px;"
          class="btn-icon pl-0 mb-50 pb-25"
          @click="hide"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            size="25"
          />
        </b-button>

        <div
          class="b-sidebar-body mt-0"
          style="padding: 10px 15px;"
        >
          <h3>Reactivate Subscription</h3>
        </div>

        <b-card
          no-body
          class="overflow-hidden mx-1"
          style="height: 500px;"
        >
            <p class="mb-0 pb-0" style="font-weight: bold;">Plan:</p>
            <span>{{idsite.plan}} {{idsite.pages}} Licensed<span style="text-transform: capitalize;" v-if="idsite.paidRecurrency"> - {{idsite.paidRecurrency}}</span></span>

            <p class="mb-0 pb-0 mt-1" style="font-weight: bold;">Price:</p>
            <span>{{ idsite.paidRecurrency === 'year' ? (idsite.planType === 5 ? '$'+123 : idsite.planType === 10 ? '$'+140 : idsite.planType === 25 ? '$'+195 : idsite.planType >= 50 ? '$'+(220 + (idsite.planType - 50)) : '$'+20) : (idsite.planType === 5 ? '$'+5 : idsite.planType === 10 ? '$'+8 : idsite.planType === 25 ? '$'+10 : idsite.planType >= 50 ? '$'+(12 + ((idsite.planType - 50) / 10)) : '$'+20) }}.00</span>
            
            <p class="mb-0 pb-0 mt-1" style="font-weight: bold;">Date of the next due:</p>
            <span>{{idsite.nextDue}}</span>
            
            
            <b-button
                :style="'background: #7838ff !important; border: 1px solid #7838ff !important; width: 208px; height: 46px; border-radius: 15px; margin-right: 40px;'"
                class="float-right mt-1"
                variant="primary"
                @click.prevent="submitEditar"
                :disabled="processCancel ? true : false"
            >
                <span
                class=""
                style="font-weight: 700; color: #FFFFFF;"
                >Reactivate subscription</span>
            </b-button>
        </b-card>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BFormSelect,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import VueSlider from 'vue-slider-component'
import storeInd from '@/store/index'
import 'vue-slider-component/theme/default.css'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import moment from 'moment'
import { db, firebase } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BImgLazy,
    VueSlider,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenReactivateSubscription',
    event: 'update:show-open-reactivate-subscription',
  },
  props: ['idsite','userData','showOpenReactivateSubscription'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      dir: 'ltr',
      customSlider: 50,
      annualyBill: false,
      selectedMove: '',
      processSubmit: false,
      numberPages: 0,
      crawlType: 0,
      sitesImg: require('@/assets/images/illustration/i-site.png'),
      siteJS: require('@/assets/images/icons/js.png'),
      marks: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000],

      agentesAsignados: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      selectedProPage: '',
      optionsProPage: [
        'Home',
        'Blog',
        'Contact',
      ],
      selectedError: 'Missing Title',
      optionsErrors: [
        'Meta title',
        'Missing Title',
        'Missing descriptions',
        'Missing alts',
        'Headings H1',
        'Low word count',
        'External links',
      ],
      fixText: '',
      customPlanMonth: 12,
      customPlan: 220,
      processCancel: false,
      openSubs: false,

      selectedCancelReason: '',
      optionsCancelReason: [
        '',
        'No longer required',
        'Switched to a competitor',
        'Built functionality in-house',
        "It's not working as I/we expected",
        "It's missing a feature",
        'Something else'
      ],

      //   sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),
      appImgComingSoon: require('@/assets/images/icons/logo-coming-soon.svg'),
    }
  },
  watch: {
    showOpenReactivateSubscription(val) {
      this.customSlider = 50
      this.customPlanMonth = 12
      this.customPlan = 220
      this.annualyBill = false
      this.processSubmit = false
      this.selectedCancelReason = ''
      this.processCancel = false
      if(val) {
        var itmuPG = localStorage.getItem('reactivateUpg')
        this.openSubs = false
        if(itmuPG) {
          localStorage.removeItem('reactivateUpg')
          this.openSubs = true
        }
        document.body.style.overflow = 'hidden'
      } else {
        this.openSubs = false
        document.body.style.overflow = 'auto'
        // localStorage.setItem('siteLicenseChanges', true)
      }
    }
  },
  computed: {
    direction() {
      if(storeInd.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  validations: {
  },
  methods: {
    submitEditar() {
        if(this.idsite.subscriptionId) {
            this.processCancel = true
            let config = {
                method: 'post',
                // maxBodyLength: Infinity,
                url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/reactivate-subscription',
                headers: { 
                'Content-Type': 'application/json'
                },
                data: JSON.stringify({subscriptionId: this.idsite.subscriptionId})
            };
        
            this.axios(config)
            .then((response) => {
                // console.log(response.data);
      
                db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
                  // plan: 'free',
                  // planType: '0',
                  // due: null,
                  cancelSub: false
                //   cancelationReason: this.selectedCancelReason
                })
                .then(() => {
                  localStorage.setItem('siteLicenseChanges', true)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Subscription reactivated',
                            icon: 'BellIcon',
                            variant: 'success',
                        },
                    })

                    this.$refs.sidebar_reactivate_subscription.hide()

                    if(this.openSubs) {
                      localStorage.setItem('licenseProcess', JSON.stringify(this.idsite))
                    }

                    // this.$router.push({
                    //   name: 'view-errors',
                    //   query:{
                    //     lic: 'upd'
                    //   },
                    //   params: {
                    //     id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
                    //   },
                    // })
                    setTimeout(() => {
                      this.processSubmit = false
                    }, 2000)
                })
            })
            .catch(e => {
                console.log(e.message);
                this.processCancel = false
            })
        }
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.reactivate-option-reason {
  font-family: 'Hurme Geometric Sans 3';
  margin-top: 5px;
}
.reactivate-option-reason option {
  color: rgb(75 85 99);
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  /* font-family: 'Hurme Geometric Sans 3'; */
  font-family: Tahoma,Geneva,sans-serif
}
input[type="radio"]:checked {
  background-color: #7838FF;
  padding: 4px;
  border: 5px solid #7838FF;
}
input[type=radio]{
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-clip: content-box;
  border: 2px solid #DCDCF2;
  background-color: #F0F1F7;
}
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
.slider-custom-plan .vue-slider-process {
  background-color: #7838ff;
}
.slider-custom-plan .vue-slider-dot-tooltip-inner {
  background-color: #7838ff;
  border-color: #7838ff;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-reactivate-subscription {
  width: 90%;
  max-width: 593px !important;
}
</style>