<template>
  <div>
    <b-sidebar
      id="sidebar-right-members-project"
      ref="sidebar_members_project"
      :visible="showOpenMembersProject"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-members-project', val)"
    >
      <template #default="{ hide }">
        <b-overlay
          :show="loader"
          rounded="xl"
          no-fade
        >
          <div :aria-hidden="loader ? 'true' : null">
            <div
              class="d-flex justify-content-between align-items-center content-sidebar-header px-1 py-0"
              style="height: 81px; background: #fff;"
            >
              <b-card
                no-body
                class="overflow-hidden w-80 mb-0"
                style=""
              >
                <h3
                  class="mb-0 pb-0 pl-2 mt-0 pt-2"
                  style="font-weight: 700; color: #181E38 !important; padding: 40px;"
                >
                  Share project
                </h3>
              </b-card>
              <feather-icon
                class="cursor-pointer pt-0 mt-0"
                icon="XIcon"
                size="20"
                @click="hide"
              />
            </div>
            <!-- <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50" style="background-color: #F0F1F7; height: 91px;">
                        <b-card no-body class="overflow-hidden w-100" style="background-color: #F0F1F7;">
                            <b-row no-gutters class="mt-1 ml-2">
                                <b-col md="3">
                                    <b-card-img-lazy :src="idsite.imgSite" alt="Image" style="width: 100px !important; height: 58px;" class="rounded-0"></b-card-img-lazy>
                                </b-col>
                                <b-col md="9" class="pl-0">
                                    <b-card-text class="mb-0 pt-1" style="font-weight: 700; color: #181E38; font-size: 15px;">{{idsite.name}}</b-card-text>
                                    <span class="" style="text-decoration: underline; color: #A7AAB9; font-size: 15px;">{{idsite.domain}}</span>
                                </b-col>
                            </b-row>
                        </b-card>
                        <feather-icon
                            class="cursor-pointer"
                            icon="XIcon"
                            size="20"
                            @click="hide"
                        />
                    </div> -->
            <div class="ml-3 mb-1 mt-50">
              <p
                v-if="idsite.label"
                class="mb-0"
                style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
              >
                Project
              </p>
              <h4
                v-if="idsite.label"
                class=""
                style="font-weight: 700;"
              >
                {{ idsite.label }}
              </h4>
            </div>
            <!-- <hr v-if="idsite.label"> -->
            <div
              class="b-sidebar-body mt-0"
              style="padding: 0 40px;"
            >
              <!-- <span class="mr-50 mt-0" style="font-weight: 700; color: #181E38; font-size: 16px; display: flex; margin-top: 0px;">
                            <div :style="`width: 15px; height: 15px; border-radius: 4px; background: ${idsite.color}; margin-top: 5px;`" class="mr-50"></div>
                            <span class="mt-0">{{idsite.label}}</span>
                        </span> -->
              <validation-observer ref="simpleRulesInvitation">
                <b-form @submit.prevent>
                  <b-row
                    class="mt-1"
                    cols="12"
                  >
                    <b-col md="8">
                      <b-form-group
                        label-for="projectInvitations"
                        class="color-text-grey"
                        style="font-weight: 700;"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Invite team members"
                          rules="email"
                        >
                          <vue-autosuggest
                            v-model="projectInvitation"
                            :suggestions="filteredOptions"
                            :input-props="{
                              id: 'autosuggest__input',
                              class: 'form-control',
                              placeholder: 'Add member...',
                              disabled: idsite.permission === 'admin' || idsite.permission === 'owner' || idsite.permission === 'edit' ? false : true                              
                            }"
                            :limit="10"
                            :state="errors.length > 0 ? false:null"
                            class="mt-1 sugg"
                            @input="onInputChange"
                            @click="onInputChange"
                            @selected="selectHandler"
                            v-on:keyup.enter="validationFormInvitation"
                            style="width: 80% !important;"
                          >
                            <template slot-scope="{suggestion}">
                              <div class="d-flex align-items-center">
                                <b-avatar
                                  :src="suggestion.item.foto ? suggestion.item.foto : ''"
                                  :text="avatarText(suggestion.item.name ? suggestion.item.name : '')"
                                  style="background: #ddd !important;"
                                />
                                <div class="detail ml-50 ">
                                  <b-card-text class="mb-0">
                                    {{ suggestion.item.name }}
                                  </b-card-text>
                                  <small class="text-muted">
                                    {{ suggestion.item.email }}
                                  </small>
                                </div>
                              </div>
                            </template>
                          </vue-autosuggest>
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="2"
                      class="pl-0"
                    >
                      <v-select
                        v-model="permissionInvitation"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="permissionsTeamInv"
                        :clearable="false"
                        class="float-right style-chooser mt-1"
                        style="width: 140px; border: none;"
                      />
                    </b-col>
                    <b-col
                      md="2"
                      class="pl-0"
                    >
                      <!-- <b-button
                        variant="flat-success"
                        :style="validUser ? 'font-weight: 700; height: 42px; width: 158px; border-radius: 15px; margin-top: 15px; border: 0px !important;' : 'font-weight: 700; height: 42px; width: 158px; border-radius: 15px; margin-top: 15px; border: 0px !important;'"
                        class="ml-0"
                        disabled
                        v-if="invitationSended"
                      >
                        Invitation sent
                      </b-button> -->
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        :style="validUser ? 'font-weight: 700; height: 42px; width: 58px; border-radius: 15px; margin-top: 15px; color: #ffffff; background: #7838ff !important; border: 0px !important;' : 'font-weight: 700; height: 42px; width: 58px; border-radius: 15px; margin-top: 15px; color: #A7AAB9; background: #F0F1F7; border: 0px !important;'"
                        :class="`ml-0 ${invitationSending ? 'p-0' : 'p-0'}`"
                        :disabled="invitationSending"
                        v-b-tooltip.hover.top="'Send invitation'"
                        @click.prevent="validationFormInvitation"
                      >
                        <feather-icon
                          icon="SendIcon"
                          size="20"
                        />
                        <b-spinner v-if="invitationSending" small />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>

              <h3
                class="mb-0 pb-0 mt-0"
                style="font-weight: 700; color: #656B85 !important; font-size: 16px;"
              >
                Owner
              </h3>
              <div class="demo-inline-spacing">
                <b-avatar
                  size="35"
                  :src="owner.foto ? owner.foto : ''"
                  variant="light-dark"
                  class="badge-minimal mr-50"
                  alt="img user"
                  style="background: #ddd !important;"
                  :text="avatarText(owner.nombre ? (owner.nombre.includes(' ') ? owner.nombre : owner.nombre + ' ' + owner.nombre.charAt(1)) :  owner.email ? owner.email + ' ' + owner.email.charAt(1): 'AN')"
                >
                  <feather-icon
                    v-if="!owner.nombre && !owner.foto && !owner.email"
                    icon="UserIcon"
                    size="22"
                  />
                </b-avatar>

                <div class="ml-25">
                  <p
                    class="mb-0 "
                    style="font-size: 14px; line-height: 10px;"
                  >
                    {{ owner.nombre }}
                  </p>
                  <span
                    class="user-status "
                    style="color: #A7AAB9; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;"
                  >{{ owner.email }}</span>
                </div>
              </div>

              <h3
                class="mb-0 pb-0 mt-2"
                style="font-weight: 700; color: #656B85 !important; font-size: 16px;"
              >
                Team members
              </h3>
              <b-list-group
                flush
                class="w-100 mb-4"
                :style="projectInvitations.length >= 10 ? 'max-height: calc(100vh - 412px); overflow-y: auto;' : 'max-height: calc(100vh - 412px);'"
              >
                <span class="d-none">
                  {{ titlePend = 0 }}
                </span>
                <b-list-group-item
                  v-for="(member, index) in projectInvitations"
                  :key="index"
                  :class="member.status === 'invitated' && !member.invitation && titlePend === 0 ? 'pl-0 pr-0 pt-0 pb-50 member-list-item' : 'pl-0 py-50 member-list-item'"
                  :style="`${member.status === 'invitated' && !member.invitation && titlePend === 0 ? '' : 'display: flex;'} background: ${(index % 2) !== 0 ? 'rgba(0,0,0,.05) !important' : 'white !important'}`"
                >
                  <span class="d-none">
                    {{ member.status === 'invitated' && !member.invitation ? titlePend += 1 : '' }}
                  </span>
                  <hr class="mt-0 pt-0 mb-0" v-if="titlePend === 1">
                  <h3
                    class="mb-50 pb-0 mt-1 pl-1 pt-75"
                    style="font-weight: 700; color: #656B85 !important; font-size: 16px; background: #fff !important; margin: 0px !important; width: 100% !important; height: 60px; padding-top: 1.5rem !important;"
                    v-if="titlePend === 1"
                  >
                    Pending
                  </h3>
                  <hr class="mt-0 pt-0" v-if="titlePend === 1">
                  <div class="demo-inline-spacing mt-0 ml-50" :style="titlePend === 1 ? 'float: left; padding-top: 20px;' : ''">
                    <b-avatar
                      variant="info"
                      style="background: #ddd !important;"
                      class="mt-50"
                      :src="member.foto"
                      :text="avatarText(member.name ? (member.name.includes(' ') ? member.name : member.name + ' ' + member.name.charAt(1)) :  member.email ? member.email + ' ' + member.email.charAt(1): 'AN')"
                    >
                      <feather-icon
                        v-if="!member.foto && !member.name && !member.email"
                        icon="UserIcon"
                        size="16"
                      />
                    </b-avatar>
                    <div class="ml-25 mt-50">
                      <p
                        class="mb-0 "
                        style="font-size: 14px; line-height: 10px; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: block;"
                      >
                        {{ member.statusInv === 'declined' ? member.email : member.name }}
                      </p>
                      <span
                        v-if="member.statusInv === 'declined'"
                        class="user-status "
                        style="color: #FC7D7D;"
                      >Invitation declined</span>
                      <span
                        v-else
                        class="user-status "
                        style="color: #A7AAB9; font-size: 13px; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: block;"
                      >
                        {{ member.email }}
                      </span>
                    </div>
                  </div>
                  <!-- {{ member.permission.title ? statusPermission = member.permission.title : '' }} -->
                  <!-- <span class="d-none"> -->
                    <!-- {{ statusPermission = member.permission.title ? member.permission.title : member.permission }} -->
                  <!-- </span> -->
                  <div :style="member.status === 'invitated' ? 'height: 85px;' : ''">
                    <v-select
                      v-if="member.permission.title && member.permission.title === 'Remove'"
                      v-model="removedText"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="idsite.permission === 'edit' ? permissionsTeamEdit : permissionsTeam"
                      :clearable="false"
                      :class="idsite.permission === 'admin' ? 'float-right style-chooser mt-50 mr-50' : removedText === 'view' && removedText === 'edit' && (idsite.permission === 'owner' || idsite.permission === 'edit') ? 'float-right style-chooser-disabled mt-50 mr-50' : member.permission.title === 'Remove' || member.permission === 'remove' || member.permission === 'removed' ? 'float-right style-chooser-removed mt-50 mr-50' : 'float-right style-chooser mt-50 mr-50'"
                      :style="member.permission.title === 'Remove' || member.permission === 'remove' || member.permission === 'removed' ? 'width: 140px; border: none; position: absolute !important; right: 0px; color: #FC7D7D !important;' : 'width: 140px; border: none; position: absolute !important; right: 0px;'"
                      v-on:input="changeMemberPermission(member)"
                      :disabled="idsite.permission === 'admin' ? false : removedText !== 'admin' && (idsite.permission === 'owner' || idsite.permission === 'edit') ? false : true"
                    />
                    <v-select
                      v-else-if="member.status === 'active' || member.status === 'inactive' || member.status === 'invitated'"
                      v-model="member.permission"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="idsite.permission === 'edit' ? permissionsTeamEdit : permissionsTeam"
                      :clearable="false"
                      :class="idsite.permission === 'admin' ? 'float-right style-chooser mt-50 mr-50' : member.permission === 'view' && member.permission === 'edit' && (idsite.permission === 'owner' || idsite.permission === 'edit') ? 'float-right style-chooser-disabled mt-50 mr-50' : member.permission.title === 'Remove' || member.permission === 'remove' || member.permission === 'removed' ? 'float-right style-chooser-removed mt-50 mr-50' : 'float-right style-chooser mt-50 mr-50'"
                      :style="member.permission.title === 'Remove' || member.permission === 'remove' || member.permission === 'removed' ? 'width: 140px; border: none; position: absolute !important; right: 0px; color: #FC7D7D !important;' : 'width: 140px; border: none; position: absolute !important; right: 0px;'"
                      v-on:input="changeMemberPermission(member)"
                      :disabled="idsite.permission === 'admin' ? false : member.permission !== 'admin' && (idsite.permission === 'owner' || idsite.permission === 'edit') ? false : true"
                    />
                    <b-button
                      v-if="member.status === 'invitated' && !member.invitation && (member.permission !== 'remove' && member.permission !== 'Remove' && member.permission !== 'removed' && member.permission !== 'Removed')"
                      style="width: 149px; height: 30px; background: #F0F1F7 !important; border: 1px solid #CFD3E5 !important; border-radius: 5px; color: #656B85 !important; position: absolute !important; right: 0px; margin-top: 48px !important;"
                      class="px-0 pt-50 mt-50 mr-50"
                      @click.stop.prevent="resendInvitation({uid: member.uid, id: member.id, email: member.email, project: idsite.label}), member.statusInv = 'sended', member.invitation = 'sended'"
                      :disabled="idsite.permission === 'admin' || idsite.permission === 'owner' || idsite.permission === 'edit' ? false : true"
                    >
                      <feather-icon icon="CornerUpRightIcon" />
                      <span>Resend invitation</span>
                    </b-button>
                    <b-button
                      v-else-if="member.invitation === 'sended'"
                      variant="flat-success"
                      style="width: 149px; height: 30px; position: absolute !important; right: 0px; margin-top: 48px !important; font-weight: 700; border-radius: 15px; border: 0px !important;"
                      class="px-0 pt-50 mt-50 mr-50"
                      disabled
                    >
                      Invitation sent
                    </b-button>
                    <span class="d-none">
                      <!-- {{ member.permission.title ? changePermission = member : '' }} -->
                      {{ member.permission.title ? member.permission = member.permission.title : '' }}
                    </span>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <!-- <b-button
                        style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px;"
                        class="mt-4"
                        variant="primary"
                        @click="hide"
                        >
                            <span class="align-middle" style="color: #FFFFFF;">Save</span>
                        </b-button> -->
            </div>
          </div>
        </b-overlay>
      </template>

    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BOverlay, BSpinner,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
// import { required, minLength, password, confirmed, email, } from 'vuelidate/lib/validators'
import { required, url, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { mapActions } from 'vuex'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { auth, db, firebase } from '@/firebase'

export default {
  name: 'MembersProject',
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BOverlay,
    BSpinner,
    ClaimOwnership,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenMembersProject',
    event: 'update:show-open-members-project',
  },
  props: ['idsite', 'showOpenMembersProject'],
  data() {
    return {
      email,
      required,
      //   password,
      //   confirmed,

      selectedMove: '',

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      projectInvitation: '',
      objInvitation: '',
      projectInvitations: [],
      permissionsTeam: [{ title: 'Viewer' }, { title: 'Editor' }, { title: 'Admin/editor' }, { title: 'Remove' }],
      permissionsTeamEdit: [{ title: 'Viewer' }, { title: 'Editor' }, { title: 'Remove' }],
      permissionsTeamInv: [{ title: 'Viewer' }, { title: 'Editor' }, { title: 'Admin/editor' }],
      members: [],
      owner: '',
      loader: true,
      projectInvitationsDOM: [
        // {email: 'museits@gmail.com', permission: { title: 'Edit' }, status: 'active', statusInv: 'acepted', foto: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/users%2FtHDo5r5cdFbVlSHyhs7oheN7Ol03%2F8.png?alt=media&token=ba184d16-c49e-44ad-a217-9286331b870f', name: 'Edmond Chicon'},
        // {email: 'museits@gmail.com', permission: { title: 'Edit' }, status: 'active', statusInv: 'acepted', foto: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/users%2FtHDo5r5cdFbVlSHyhs7oheN7Ol03%2F8.png?alt=media&token=ba184d16-c49e-44ad-a217-9286331b870f', name: 'Edmond Chicon'},
        // {email: 'museits@gmail.com', permission: { title: 'View' }, status: 'active', statusInv: 'acepted', foto: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/users%2FtHDo5r5cdFbVlSHyhs7oheN7Ol03%2F8.png?alt=media&token=ba184d16-c49e-44ad-a217-9286331b870f', name: 'Edmond Chicon'},
        // {email: 'museits@gmail.com', permission: { title: 'Remove' }, status: 'invitated', statusInv: 'refused', foto: '', name: ''},
      ],
      permission: { title: 'Viewer' },
      permissionInvitation: { title: 'Viewer' },

      // suggest select
      datasuggest: [],
      datasuggestSort: [],
      sortedComplete: false,
      totalUserMember: 0,
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Add member...',
      },
      limit: 10,
      selected: null,
      sectionConfigs: {
        destinations: {
          limit: 6,
          label: 'Destination',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
        hotels: {
          limit: 6,
          label: 'Hotels',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
      },
      validUser: false,
      changePermission: '',
      memberUpdated: '',
      statusPermission: '',
      firstChargePermissions: true,
      removedText: 'removed',
      counter: 0,
      invitationSending: false,
      invitationSended: false,
    }
  },
  watch: {
    idsite(val) {
      // console.log(val)
      this.loader = true
      if(val && val.idsite) {
        // console.log(val.idsite)
        if(val.idsite !== 'w_p') {
          db.collection('projects').doc(val.idsite).get()
          .then(project => {
            this.owner = project.data().owned
            db.collection('Users').doc(project.data().owned.uid).get()
              .then(queryUs => {
                this.owner.foto = queryUs.data().foto
              })
              .catch(error => console.log(error))

            db.collection('projects').doc(project.id).collection('members').orderBy('email','asc').get()
              .then(members => {
                this.projectInvitations = []
                var activeInv = []
                var removedInv = []
                var rejectInv = []
                var invitedInv = []
                members.forEach(ele => {
                  if (ele.id !== 'resume') {
                    if (ele.data().uid) {
                      // db.collection('Users').doc(ele.data().uid).get()
                      // .then(queryUs => {
                        const filtMem = this.projectInvitations.filter(mem => mem.email === ele.data().email)
                        if (filtMem.length === 0) {
                          if(ele.data().status === 'active' && ele.data().statusInv === 'accepted') {
                            activeInv.push({
                              email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: ele.data().imgU, name: ele.data().name, uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                            })
                          } else if(ele.data().perm === 'remove') {
                            removedInv.push({
                              email: ele.data().email, permission: 'removed', status: ele.data().status, statusInv: ele.data().statusInv, foto: ele.data().imgU, name: ele.data().name, uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                            })
                          } else if(ele.data().statusInv === 'declined') {
                            rejectInv.push({
                              email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: ele.data().imgU, name: ele.data().name, uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                            })
                          } else if(ele.data().status === 'invitated' && ele.data().statusInv === 'sended') {
                            invitedInv.push({
                              email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: ele.data().imgU, name: ele.data().name, uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                            })
                          }
                          // this.projectInvitations.push({
                          //   email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: queryUs.data().foto, name: queryUs.data().nombre, uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                          // })
                        }
                      // })
                      // .catch(error => console.log(error))
                    } else {
                      const filtMem = this.projectInvitations.filter(mem => mem.email === ele.data().email)
                      if (filtMem.length === 0) {
                        if(ele.data().status === 'active' && ele.data().statusInv === 'accepted') {
                          activeInv.push({
                            email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: '', name: '', uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                          })
                        } else if(ele.data().perm === 'remove') {
                          removedInv.push({
                            email: ele.data().email, permission: 'removed', status: ele.data().status, statusInv: ele.data().statusInv, foto: '', name: '', uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                          })
                        } else if(ele.data().statusInv === 'declined') {
                          rejectInv.push({
                            email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: '', name: '', uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                          })
                        } else if(ele.data().status === 'invitated' && ele.data().statusInv === 'sended') {
                          invitedInv.push({
                            email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: '', name: '', uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                          })
                        }
                        // this.projectInvitations.push({
                        //   email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: '', name: '', uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                        // })
                      }
                    }
                  }
                })
                // console.log(activeInv,removedInv,rejectInv,invitedInv)
                activeInv.forEach(ele => {
                  this.projectInvitations.push(ele)
                })
                removedInv.forEach(ele => {
                  this.projectInvitations.push(ele)
                })
                rejectInv.forEach(ele => {
                  this.projectInvitations.push(ele)
                })
                invitedInv.forEach(ele => {
                  this.projectInvitations.push(ele)
                })
                this.loader = false
              })
              .catch(error => console.log(error))
          })
          .catch(error => console.log(error))
        }
      }
    },
    showOpenMembersProject(val) {
      // console.log(val)
      this.invitationSending = false
      this.invitationSended = false
      this.idsite.idsite = this.$route.params.project ? this.$route.params.project : this.idsite.idsite
      this.charge()
      this.firstChargePermissions = true
      
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    projectInvitation(val) {
      const validateEmail = email => {
        const re = /\S+@\S+\.\S+/
        return re.test(email)
      }
      this.validUser = validateEmail(val)
      // console.log(validateEmail(val));
    },
    changePermission(val) {
      // console.log(val)
      // if(this.firstChargePermissions) {
      //   setTimeout(() => {
      //     this.firstChargePermissions = false
      //   }, 1500)
      // } else {
        // console.log(val.email,val.permission,this.idsite.idsite,this.statusPermission)
        var status = ''
        var statusInv = ''
        var perm = ''
        if(val.permission.title === 'Editor') {
          status = 'active'
          statusInv = 'accepted'
          perm = 'edit'
        } else if(val.permission.title === 'Remove') {
          status = 'inactive'
          statusInv = 'accepted'
          perm = 'remove'
        } else if(val.permission.title === 'Viewer') {
          status = 'active'
          statusInv = 'accepted'
          perm = 'view'
        } else if(val.permission.title === 'Admin/editor') {
          status = 'active'
          statusInv = 'accepted'
          perm = 'admin'
        }
        this.loader = true
        // console.log(this.memberUpdated.id, val.id, this.memberUpdated.perm, perm)
        // if(this.memberUpdated.id !== val.id) {
          db.collection('projects').doc(this.idsite.idsite).collection('members').doc(val.id).get()
          .then(docMember => {
            this.memberUpdated = docMember.data()
            this.memberUpdated.id = docMember.id
            this.memberUpdated.perm = perm

            if(docMember.data().status === 'invitated') {
              status = 'invitated'
              statusInv = 'sended'
            }
            db.collection('projects').doc(this.idsite.idsite).collection('members').doc(val.id).update({
              status: status,
              statusInv: statusInv,
              perm: perm
            })
            .then(() => {
              if(perm === 'remove') {
                const user = auth.currentUser
                if(user) {
                  db.collection('Users').doc(user.uid).update({
                    updated: Date.now(),
                  })
                }
              }
              // console.log('first')
              // this.charge()
              this.loader = false
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: 'Permissions updated',
              //     icon: 'BellIcon',
              //     variant: 'success',
              //   },
              // })
            })
          })
        // } else {
        //   this.loader = false
        // }
      // }
    },
    datasuggestSort(val) {
      if(val.length === this.totalUserMember && !this.sortedComplete) {
        this.sortedComplete = true
        this.sortMembers()
      }
    },
  },
  created() {
    this.charge()

    const user = auth.currentUser
    if (user) {
      // db.collection('Users').doc(user.uid).get()
      // .then(userDoc => {
      //     this.userData = userDoc.data()
      //     if(userDoc.data().teams && userDoc.data().teams.length >= 1) {
      //         userDoc.data().teams.forEach(element => {
      //             db.collection('projects').doc(element).get()
      //             .then(project => {
      //                 if(project.data().invitations && project.data().invitations.length >= 1) {
      //                     project.data().invitations.forEach(item => {
      //                         db.collection('Users').where('email', '==', item).get()
      //                         .then(queryUs => {
      //                             if(queryUs.size >= 1) {
      //                                 queryUs.forEach(que => {
      //                                     this.datasuggestSort.push({name: que.data().nombre, email: que.data().email, foto: que.data().foto})
      //                                 })
      //                             }
      //                         })
      //                     })
      //                 }
      //             })
      //         });
      //         // this.datasuggestSort.push({name: user.data().nombre, email: user.data().email, foto: user.data().foto})
      //     }
      // })

      // const user = auth.currentUser
      // if(user) {
      db.collection('Users').doc(user.uid).get()
      .then(queryUs => {
        if(queryUs.data().teams && queryUs.data().teams.length >= 1) {
          queryUs.data().teams.forEach(element => {
            db.collection('projects').doc(element).collection('members').where('uid', '!=', 'resume').get()
            .then(members => {
              this.datasuggest = []
              this.datasuggestSort = []
              members.forEach(ele => {
                if (user.uid !== ele.data().uid) {
                  if (ele.id !== 'resume') {
                    if (ele.data().uid) {
                      db.collection('Users').doc(ele.data().uid).get()
                        .then(docUs => {
                          // console.log(element,docUs.id,docUs.data().teams)
                          // console.log(docUs.data().teams.includes(this.$route.params.project))
                          if(docUs.data().teams) {
                            if(!docUs.data().teams.includes(this.$route.params.project)) {
                              const filtMem = this.datasuggestSort.filter(mem => mem.email === ele.data().email)
                              if (filtMem.length === 0) {
                                this.totalUserMember += 1
                                this.datasuggestSort.push({ name: docUs.data().nombre, email: docUs.data().email, foto: docUs.data().foto })
                              }
                            }
                          }
                        })
                        .catch(error => console.log(error))
                    } else {
                      const filtMem = this.datasuggestSort.filter(mem => mem.email === ele.data().email)
                      if (filtMem.length === 0) {
                        this.totalUserMember += 1
                        this.datasuggestSort.push({ name: ele.data().email, email: ele.data().email, foto: '' })
                      }
                    }
                  }
                }
              })
              this.loader = false
            })
            .catch(error => console.log(error))
          })
        }
      })
      .catch(error => console.log(error))
        // }
    }
  },
  //   validations: {
  //   },
  methods: {
    sortMembers() {
      this.datasuggestSort.sort(function (a, b) {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
      });
      this.datasuggestSort.forEach(item => {
        // console.log(item.name)
        this.datasuggest.push(item)
      })
      // console.log(this.datasuggest)
    },
    changeMemberPermission(val) {
      // console.log('first',a)
      // console.log(val)
      var status = ''
      var statusInv = ''
      var perm = ''
      if(val.permission.title === 'Editor' || val.permission === 'Editor') {
        status = val.status === 'invitated' ? val.status : 'active'
        statusInv = val.statusInv === 'sended' ? val.statusInv : 'accepted'
        perm = 'edit'
      } else if(val.permission.title === 'Remove' || val.permission === 'Remove') {
        status = 'inactive'
        statusInv = val.statusInv === 'sended' ? val.statusInv : 'accepted'
        perm = 'remove'
      } else if(val.permission.title === 'Viewer' || val.permission === 'Viewer') {
        status = val.status === 'invitated' ? val.status : 'active'
        statusInv = val.statusInv === 'sended' ? val.statusInv : 'accepted'
        perm = 'view'
      } else if(val.permission.title === 'Admin/editor'  || val.permission === 'Admin/editor') {
        status = val.status === 'invitated' ? val.status : 'active'
        statusInv = val.statusInv === 'sended' ? val.statusInv : 'accepted'
        perm = 'admin'
      }
      this.loader = true
      // console.log(val.id)
      db.collection('projects').doc(this.idsite.idsite).collection('members').doc(val.id).get()
      .then(docMember => {
        this.memberUpdated = ''
        this.memberUpdated = docMember.data()
        this.memberUpdated.id = docMember.id
        this.memberUpdated.perm = perm
        
        if(docMember.data().status === 'invitated') {
          status = 'invitated'
          statusInv = 'sended'
        }
        db.collection('projects').doc(this.idsite.idsite).collection('members').doc(val.id).update({
          status: status,
          statusInv: statusInv,
          perm: perm
        })
        .then(() => {
          if(perm === 'remove') {
            const user = auth.currentUser
            if(user) {
              db.collection('Users').doc(user.uid).update({
                updated: Date.now(),
              })
            }
          }
          // console.log('first')
          // this.charge()
          this.loader = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Permissions updated',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => console.log(e))
      })
      .catch(e => console.log(e))
    },
    choseColor() {
      // console.log(this.counter)
      return ((this.counter % 2) !== 0) ? `rgba(0,0,0,.05)` : 'white';
    },
    submitEditar(payload) {
      this.$refs.sidebar_members_project.hide()
    },
    validationFormInvitation() {
      this.$refs.simpleRulesInvitation.validate().then(success => {
        if (success) {
          const user = auth.currentUser
          // console.log(user.email,this.projectInvitation.trim())
          if(user.email === this.projectInvitation.trim()) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.projectInvitation} is already the owner of this project.`,
                icon: 'CoffeeIcon',
                variant: 'danger',
              },
            })
            this.projectInvitation = ''
            this.objInvitation = ''
          } else {
            if(this.projectInvitation) {
              var perm = ''
              if(this.permissionInvitation.title === 'Editor' || this.permissionInvitation === 'Editor') {
                perm = 'edit'
              } else if(this.permissionInvitation.title === 'Remove' || this.permissionInvitation === 'Remove') {
                perm = 'remove'
              } else if(this.permissionInvitation.title === 'Viewer' || this.permissionInvitation === 'Viewer') {
                perm = 'view'
              } else if(this.permissionInvitation.title === 'Admin/editor'  || this.permissionInvitation === 'Admin/editor') {
                perm = 'admin'
              }
              const filterEmail = this.projectInvitations.filter(item => item.email === this.projectInvitation)
              if(this.projectInvitations.length === 0 || filterEmail.length === 0) {
                this.invitationSending = true
                this.loader = true
                this.projectInvitations.push({
                  email: this.projectInvitation,
                  permission: perm,
                  status: 'invitated',
                  statusInv: 'sended',
                  // status: 'active',
                  // statusInv: 'accepted',
                  foto: this.objInvitation.foto,
                  name: this.objInvitation.name,
                  id: ''
                })

                // update members db
                // if(item.email.includes('@') && item.email.includes('.')) {
                const membersEmail = []
                const members = []
                const email = this.projectInvitation.trim()
                db.collection('Users').where('email', '==', email).get()
                .then(queryUser => {
                  let member = ''
                  // verify if user already exist, to add uid
                  if (queryUser.size >= 1) {
                    queryUser.forEach(userItem => {
                      members.push({
                        uid: userItem.id,
                        email,
                        perm: perm,
                        imgU: userItem.data().foto ? userItem.data().foto : '',
                        status: 'invitated',
                        statusInv: 'sended',
                        // status: 'active',
                        // statusInv: 'accepted',
                      })

                      member = {
                        uid: userItem.id,
                        email,
                        perm: perm,
                        imgU: userItem.data().foto ? userItem.data().foto : '',
                        status: 'invitated',
                        statusInv: 'sended',
                        // status: 'active',
                        // statusInv: 'accepted',
                      }

                      // update teams of user belongs
                      if (userItem.data().teams) {
                        db.collection('Users').doc(userItem.id).update({
                          teams: firebase.firestore.FieldValue.arrayUnion(this.idsite.idsite),
                        })
                          .catch(error => {
                            console.error(error)
                          })
                      } else {
                        db.collection('Users').doc(userItem.id).update({
                          teams: [this.idsite.idsite],
                        })
                          .catch(error => {
                            console.error(error)
                          })
                      }
                    })
                  } else {
                    members.push({
                      uid: '',
                      email,
                      perm: perm,
                      status: 'invitated',
                      statusInv: 'sended',
                      // status: 'active',
                      // statusInv: 'accepted',
                    })

                    member = {
                      uid: '',
                      email,
                      perm: perm,
                      status: 'invitated',
                      statusInv: 'sended',
                      // status: 'active',
                      // statusInv: 'accepted',
                    }
                  }

                  // send invitation mails
                  db.collection('projects').doc(this.idsite.idsite).get()
                  .then(projectDoc => {
                    const obj = {
                      email,
                      name: this.owner.nombre ? this.owner.nombre : this.owner.email ? this.owner.email : '',
                      project: projectDoc.data().name,
                      existUser: queryUser.size >= 1 ? true : false
                    }

                    // update seocloud-dcdfb
                    // const config = {
                    //   method: 'POST',
                    //   url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/invitationMail',
                    //   headers: {
                    //     'Content-Type': 'application/json',
                    //   },
                    //   data: JSON.stringify(obj),
                    // }

                    // this.axios(config)
                    // .then(() => {
                    //   this.invitationSending = false
                    //   this.invitationSended = true
                    //   this.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //       title: 'Invitation sent',
                    //       icon: 'BellIcon',
                    //       variant: 'success',
                    //     },
                    //   })
                    //   console.log('email sended')
                    // })
                    // .catch(error => {
                    //   console.log(error)
                    // })
                  })
                  // console.log(member,this.idsite.idsite)

                  db.collection('projects').doc(this.idsite.idsite).collection('members').add(member)
                  .then((docRef) => {
                    // console.log('first')
                    this.projectInvitations.forEach(ele => {
                      if(ele.email === email) {
                        // console.log(docRef.id)
                        ele.id = docRef.id
                      }
                    })
                    this.loader = false
                  })
                  .catch(error => {
                    console.error(error)
                  })
                  
                  if(user) {
                    db.collection('Users').doc(user.uid).update({
                      updated: Date.now(),
                    })
                  }
                })
                membersEmail.push(email)
                // }
                this.projectInvitation = ''
                this.objInvitation = ''
              } else {
                // this.projectInvitations.push({email: this.projectInvitation, perm: this.permission.title})
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `${this.projectInvitation} is already a team member.`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                  },
                })
                this.projectInvitation = ''
                this.objInvitation = ''
              }
              // console.log(this.projectInvitations)
            }
          }
          // this.submitProject()
        }
      })
    },
    resendInvitation(payload) {
      // send invitation mails
      this.invitationSending = true
      db.collection('Users').where('email', '==', payload.email).get()
      .then(queryUs => {
        if(queryUs.size >= 1) {
          // queryUs.forEach(que => {
          const obj = {
            email: payload.email,
            name: this.owner.nombre ? this.owner.nombre : this.owner.email ? this.owner.email : '',
            project: payload.project,
            existUser: queryUs.size >= 1 ? true : false
          }

          // update seocloud-dcdfb
          // const config = {
          //   url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/invitationMail',
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          //   data: JSON.stringify(obj),
          // }

          // this.axios(config)
          // .then(() => {
          //   this.invitationSending = false
          //   // this.charge()
          //   this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: 'Invitation sent',
          //       icon: 'BellIcon',
          //       variant: 'success',
          //     },
          //   })

          //   console.log('email sended')
          //   db.collection('projects').doc(this.idsite.idsite).collection('members').doc(payload.id).update({
          //     status: 'invitated',
          //     statusInv: 'sended',
          //     // status: 'active',
          //     // statusInv: 'accepted',
          //   })
          //   // .then(() => {
          //   //     this.$toast({
          //   //         component: ToastificationContent,
          //   //         props: {
          //   //             title: 'Invitation sent',
          //   //             icon: 'BellIcon',
          //   //             variant: 'success'
          //   //         },
          //   //     })
          //   // })

          //   db.collection('Users').doc(payload.uid).update({
          //     updated: Date.now(),
          //   })
          // })
          // .catch(error => {
          //   console.log(error)
          // })
          // })
        } else {
          const obj = {
            email: payload.email,
            name: this.owner.nombre ? this.owner.nombre : this.owner.email ? this.owner.email : '',
            project: payload.project,
            existUser: queryUs.size >= 1 ? true : false
          }

          // update seocloud-dcdfb
          // const config = {
          //   url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/invitationMail',
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          //   data: JSON.stringify(obj),
          // }

          // this.axios(config)
          // .then(() => {
          //   this.invitationSending = false
          //   // this.charge()
          //   this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: 'Invitation sent',
          //       icon: 'BellIcon',
          //       variant: 'success',
          //     },
          //   })

          //   console.log('email sended')
          //   db.collection('projects').doc(this.idsite.idsite).collection('members').doc(payload.id)
          //     .update({
          //       status: 'invitated',
          //       statusInv: 'sended',
          //     // status: 'active',
          //     // statusInv: 'accepted',
          //     })
          //   // .then(() => {
          //   //     this.$toast({
          //   //         component: ToastificationContent,
          //   //         props: {
          //   //             title: 'Invitation sent',
          //   //             icon: 'BellIcon',
          //   //             variant: 'success'
          //   //         },
          //   //     })
          //   // })

          //   db.collection('Users').doc(payload.uid).update({
          //     updated: Date.now(),
          //   })
          // })
          // .catch(error => {
          //   console.log(error)
          // })
        }
      })
      .catch(error => {
        this.invitationSending = false
        console.log(error)
      })
    },
    charge() {
      // console.log(this.idsite)
      this.loader = true
      if (this.idsite && this.idsite.idsite) {
        if (this.$route.params.project === 'w_p') {
          this.idsite.label = 'My sites'
        } else {
          const user = auth.currentUser
          db.collection('Users').doc(user.uid).get()
          .then(queryUs => {
            if(queryUs.data().teams && queryUs.data().teams.length >= 1) {
              queryUs.data().teams.forEach(element => {
                db.collection('projects').doc(element).collection('members').where('uid', '!=', 'resume').get()
                .then(members => {
                  this.datasuggest = []
                  this.datasuggestSort = []
                  members.forEach(ele => {
                    if (user.uid !== ele.data().uid) {
                      if (ele.id !== 'resume') {
                        if (ele.data().uid) {
                          db.collection('Users').doc(ele.data().uid).get()
                            .then(docUs => {
                              // console.log(docUs.data().teams.includes(this.$route.params.project))
                              if(docUs.data().teams) {
                                if(!docUs.data().teams.includes(this.$route.params.project ? this.$route.params.project : this.idsite.idsite)) {
                                  const filtMem = this.datasuggestSort.filter(mem => mem.email === ele.data().email)
                                  if (filtMem.length === 0) {
                                    this.totalUserMember += 1
                                    this.datasuggestSort.push({ name: docUs.data().nombre, email: docUs.data().email, foto: docUs.data().foto })
                                  }
                                }
                              }
                            })
                            .catch(error => console.log(error))
                        } else {
                          const filtMem = this.datasuggestSort.filter(mem => mem.email === ele.data().email)
                          if (filtMem.length === 0) {
                            this.totalUserMember += 1
                            this.datasuggestSort.push({ name: ele.data().email, email: ele.data().email, foto: '' })
                          }
                        }
                      }
                    }
                  })
                  this.loader = false
                })
                .catch(error => console.log(error))
              })
            }
          })
          .catch(error => console.log(error))
          db.collection('projects').doc(this.$route.params.project ? this.$route.params.project : this.idsite.idsite).get()
            .then(project => {
              // console.log(project.data().name)
              this.idsite.label = project.data().name
            })
        }

        if(this.idsite.idsite !== 'w_p') {
          db.collection('projects').doc(this.idsite.idsite).get()
          .then(project => {
            this.owner = project.data().owned
            db.collection('Users').doc(project.data().owned.uid).get()
            .then(queryUs => {
              this.owner.foto = queryUs.data().foto
            })
            .catch(error => console.log(error))

            this.loader = true
            db.collection('projects').doc(project.id).collection('members').orderBy('email','asc').get()
            .then(members => {
              this.projectInvitations = []
              
              var activeInv = []
              var removedInv = []
              var rejectInv = []
              var invitedInv = []
              members.forEach(ele => {
                if (ele.id !== 'resume') {
                  // console.log(ele.data())
                  if (ele.data().uid) {
                    // db.collection('Users').doc(ele.data().uid).get()
                    // .then(queryUs => {
                      const filtMem = this.projectInvitations.filter(mem => mem.email === ele.data().email)
                      if (filtMem.length === 0) {
                        if(ele.data().status === 'active' && ele.data().statusInv === 'accepted') {
                          activeInv.push({
                            email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: ele.data().imgU, name: ele.data().name, uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                          })
                        } else if(ele.data().perm === 'remove') {
                          removedInv.push({
                            email: ele.data().email, permission: 'removed', status: ele.data().status, statusInv: ele.data().statusInv, foto: ele.data().imgU, name: ele.data().name, uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                          })
                        } else if(ele.data().statusInv === 'declined') {
                          rejectInv.push({
                            email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: ele.data().imgU, name: ele.data().name, uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                          })
                        } else if(ele.data().status === 'invitated' && ele.data().statusInv === 'sended') {
                          invitedInv.push({
                            email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: ele.data().imgU, name: ele.data().name, uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                          })
                        }
                        // this.projectInvitations.push({
                        //   email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: queryUs.data().foto, name: queryUs.data().nombre, uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                        // })
                      }
                    // })
                    // .catch(error => console.log(error))
                  } else {
                    const filtMem = this.projectInvitations.filter(mem => mem.email === ele.data().email)
                    if (filtMem.length === 0) {
                      if(ele.data().status === 'active' && ele.data().statusInv === 'accepted') {
                        activeInv.push({
                          email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: '', name: '', uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                        })
                      } else if(ele.data().perm === 'remove') {
                        removedInv.push({
                          email: ele.data().email, permission: 'removed', status: ele.data().status, statusInv: ele.data().statusInv, foto: '', name: '', uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                        })
                      } else if(ele.data().statusInv === 'declined') {
                        rejectInv.push({
                          email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: '', name: '', uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                        })
                      } else if(ele.data().status === 'invitated' && ele.data().statusInv === 'sended') {
                        invitedInv.push({
                          email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: '', name: '', uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                        })
                      }
                      // this.projectInvitations.push({
                      //   email: ele.data().email, permission: ele.data().perm === 'Edit' || ele.data().perm === 'edit' ? 'Editor' : ele.data().perm === 'View' || ele.data().perm === 'view' ? 'Viewer' : ele.data().perm === 'Admin/edit' || ele.data().perm === 'admin' ? 'Admin/editor' : ele.data().perm, status: ele.data().status, statusInv: ele.data().statusInv, foto: '', name: '', uid: ele.data().uid ? ele.data().uid : '', id: ele.id,
                      // })
                    }
                  }
                }
              })
              activeInv.forEach(ele => {
                this.projectInvitations.push(ele)
              })
              removedInv.forEach(ele => {
                this.projectInvitations.push(ele)
              })
              rejectInv.forEach(ele => {
                this.projectInvitations.push(ele)
              })
              invitedInv.forEach(ele => {
                this.projectInvitations.push(ele)
              })
              // console.log(activeInv,removedInv,rejectInv,invitedInv)
              this.loader = false
            })
            .catch(error => console.log(error))
          })
          .catch(error => console.log(error))
        }
      }
    },
    selectHandler(val) {
      // console.log(val)
      this.projectInvitation = val.item.email
      this.objInvitation = val.item
      // projectInvitation
    },
    onInputChange(text) {
      this.datasuggest = []
      this.datasuggestSort.sort(function (a, b) {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
      });
      this.datasuggestSort.forEach(item => {
        // console.log(item.name)
        this.datasuggest.push(item)
      })

      this.projectInvitations.forEach(item => {
        this.datasuggest = this.datasuggest.filter(ele => ele.email !== item.email)
      })

      if (!text) {
        const filteredData = this.datasuggest
        this.filteredOptions = [{
          data: filteredData,
        }]
      } else {
        const filteredDevelopers = this.datasuggest.filter(item => item.email.toLowerCase().indexOf(text.toLowerCase()) > -1 || item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
        const filteredData = filteredDevelopers
        this.filteredOptions = [{
          data: filteredData,
        }]
      }
    },
    // validationForm() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       // console.log({nombre: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, contact: this.contact, password: this.password, role: this.roleOptions, permissions: this.selectedPermissions})
    //       this.submitEditar({id: this.userData.id, fullName: this.userData.fullName, firstName: this.userData.firstName, lastName: this.userData.lastName, email: this.userData.email, contact: this.userData.contact, permissions: this.userData.permissions})
    //     }
    //   })
    // },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-members-project {
    width: 593px;
    max-width: 593px;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: none;
  /* color: #394066; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}
.style-chooser-removed .vs__search::placeholder,
.style-chooser-removed .vs__dropdown-toggle,
.style-chooser-removed .vs__dropdown-menu {
  background: #fff;
  border: none;
}
.style-chooser-removed span {
  color: #FC7D7D !important;
}
.style-chooser-disabled .vs__search::placeholder,
.style-chooser-disabled .vs__dropdown-toggle,
.style-chooser-disabled .vs__dropdown-menu {
  background: #f8f8f8 !important;
  border: none;
  /* color: #394066; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
.autosuggest__results > ul {
  width: 100%;
  color: rgba(30, 39, 46,1.0);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 .5rem 0;
}
.autosuggest__results > li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}
li:hover {
  cursor: pointer;
}

.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 280px;
}

#autosuggest { width: 100%; display: block;}
.autosuggest__results-item--highlighted {
  background-color: rgba(51, 217, 178,0.2);
}
#autosuggest__input {
    height: 42px;
}
.member-list-item {
  border-bottom: transparent !important;
}
</style>
