<template>
  <div>
    <b-sidebar
      id="sidebar-right-cancel-subscription"
      ref="sidebar_cancel_subscription"
      :visible="showOpenCancelSubscription"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-cancel-subscription', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-50">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>
        <hr v-if="idsite.label">
        <b-button
          variant="flat-light"
          style="color: rgb(24, 30, 56); display: block; text-align: left; width: 40px; margin-left: 10px;"
          class="btn-icon pl-0 mb-50 pb-25"
          @click="hide"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            size="25"
          />
        </b-button>

        <div
          class="b-sidebar-body mt-0"
          style="padding: 10px 15px;"
        >
          <h3>Cancel Subscription</h3>
        </div>

        <b-card
          no-body
          class="overflow-hidden mx-1"
          style="height: 500px;"
        >
            <p class="mb-0 pb-0">Please let us know why you're cancelling</p>
            <!-- <b-form-select
              v-model="selectedCancelReason"
              placeholder="Select reason"
              class="cancel-option-reason"
            >
              <b-form-select-option value="Select reason"><br><span style="padding: 10px;">Select reason</span></b-form-select-option>
              <b-form-select-option value="No longer required"><span style="padding: 10px;">No longer required</span></b-form-select-option>
              <b-form-select-option value="Switched to a competitor"><span style="padding: 10px;">Switched to a competitor</span></b-form-select-option>
              <b-form-select-option value="Built functionality in-house"><span style="padding: 10px;">Built functionality in-house</span></b-form-select-option>
              <b-form-select-option value="It's not working as I/we expected"><span style="padding: 10px;">It's not working as I/we expected</span></b-form-select-option>
              <b-form-select-option value="It's missing a feature"><span style="padding: 10px;">It's missing a feature</span></b-form-select-option>
              <b-form-select-option value="Something else"><span style="padding: 10px;">Something else</span></b-form-select-option>
            </b-form-select> -->
            <!-- <div class="custom-select">
              <div class="select-selected">{{ selectedCancelReason || "Select reason" }}</div>
              <div class="select-items">
                <div v-for="option in options" :key="option.value" @click="selectOption(option.value)">
                  {{ option.text }}
                </div>
              </div>
            </div>:reduce="option => option.value" -->
            <v-select
              v-model="selectedCancelReason"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              label="text"
              value="value"
              :options="options"
              :reduce="option => option.value"
              class="v-cancel-option-reason"
            />
            <p class="mb-0 mt-2 pb-0">Any additional feedback?</p>
            <b-form-textarea
              id="textarea-default"
              placeholder="Any additional feedback?"
              v-model="additionalFeedback"
              rows="3"
            />
            <b-button
                :style="'background: #7838ff !important; border: 1px solid #7838ff !important; width: 208px; height: 46px; border-radius: 15px; margin-right: 40px;'"
                class="float-right mt-1"
                variant="primary"
                @click.prevent="submitEditar"
                :disabled="processCancel ? true : selectedCancelReason && selectedCancelReason !== 'Select reason' ? false : true"
            >
                <span
                class=""
                style="font-weight: 700; color: #FFFFFF;"
                >Cancel subscription</span>
            </b-button>
        </b-card>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BFormSelect,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow, BFormSelectOption,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import VueSlider from 'vue-slider-component'
import storeInd from '@/store/index'
import 'vue-slider-component/theme/default.css'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import moment from 'moment'
import { db, firebase } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BImgLazy,
    BFormSelectOption,
    VueSlider,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenCancelSubscription',
    event: 'update:show-open-cancel-subscription',
  },
  props: ['idsite','userData','showOpenCancelSubscription'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      dir: 'ltr',
      customSlider: 50,
      annualyBill: false,
      selectedMove: '',
      processSubmit: false,
      numberPages: 0,
      crawlType: 0,
      sitesImg: require('@/assets/images/illustration/i-site.png'),
      siteJS: require('@/assets/images/icons/js.png'),
      marks: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000],

      agentesAsignados: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      selectedProPage: '',
      optionsProPage: [
        'Home',
        'Blog',
        'Contact',
      ],
      selectedError: 'Missing Title',
      optionsErrors: [
        'Meta title',
        'Missing Title',
        'Missing descriptions',
        'Missing alts',
        'Headings H1',
        'Low word count',
        'External links',
      ],
      fixText: '',
      customPlanMonth: 12,
      customPlan: 220,
      processCancel: false,

      additionalFeedback: '',
      selectedCancelReason: 'Select reason',
      optionsCancelReason: [
        'Select reason',
        'No longer required',
        'Switched to a competitor',
        'Built functionality in-house',
        "It's not working as I/we expected",
        "It's missing a feature",
        'Something else'
      ],
      // selectedCancelReason: '',
      options: [
        { value: "Select reason", text: "Select reason" },
        { value: "No longer required", text: "No longer required" },
        { value: "Switched to a competitor", text: "Switched to a competitor" },
        { value: "Built functionality in-house", text: "Built functionality in-house" },
        { value: "It's not working as I/we expected", text: "It's not working as I/we expected" },
        { value: "It's missing a feature", text: "It's missing a feature" },
        { value: "Something else", text: "Something else" }
      ],

      //   sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),
      appImgComingSoon: require('@/assets/images/icons/logo-coming-soon.svg'),
    }
  },
  watch: {
    showOpenCancelSubscription(val) {
      this.customSlider = 50
      this.customPlanMonth = 12
      this.customPlan = 220
      this.annualyBill = false
      this.processSubmit = false
      this.additionalFeedback = ''
      this.selectedCancelReason = 'Select reason'
      this.processCancel = false
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
        // localStorage.setItem('siteLicenseChanges', true)
      }
    }
  },
  computed: {
    direction() {
      if(storeInd.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  validations: {
  },
  methods: {
    // selectOption(value) {
    //   this.selectedCancelReason = value;
    // },
    submitEditar() {
        if(this.idsite.subscriptionId) {
            this.processCancel = true
            let config = {
                method: 'post',
                // maxBodyLength: Infinity,
                url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/cancel-subscription',
                headers: { 
                'Content-Type': 'application/json'
                },
                data: JSON.stringify({subscriptionId: this.idsite.subscriptionId})
            };
        
            this.axios(config)
            .then((response) => {
                // console.log(response.data);
      
                db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
                  // plan: 'free',
                  // planType: '0',
                  // due: null,
                  cancelSub: true,
                  cancelationReason: this.selectedCancelReason,
                  additionalFeedback: this.additionalFeedback
                })
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Subscription canceled',
                            icon: 'BellIcon',
                            variant: 'success',
                        },
                    })

                    this.$refs.sidebar_cancel_subscription.hide()
                    // localStorage.setItem('successLicense', JSON.stringify(this.idsite))

                    var entryDate = new Date()
                    var date = entryDate
                    var timestamp = firebase.firestore.Timestamp.fromDate(date);

                    db.collection('Cancellations').add({
                      user: this.userData,
                      project: this.idsite.project,
                      site: this.idsite.domain,
                      domain: this.idsite.domainFixed,
                      projectid: this.idsite.projectid,
                      siteid: this.idsite.id,
                      subscriptionId: this.idsite.subscriptionId,
                      reason: this.selectedCancelReason,
                      additionalFeedback: this.additionalFeedback,
                      due: this.idsite.nextDue,
                      date: timestamp,
                      dateFormat: firebase.firestore.FieldValue.serverTimestamp()
                    })

                    this.$router.push({
                      name: 'view-errors',
                      query:{
                        lic: 'upd'
                      },
                      params: {
                        id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
                      },
                    })
                    setTimeout(() => {
                      this.processSubmit = false
                    }, 2000)
                })
            })
            .catch(e => {
                console.log(e.message);
                this.processCancel = false
            })
        }
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.cancel-option-reason {
  font-family: 'Hurme Geometric Sans 3';
  margin-top: 5px;
}
.cancel-option-reason option {
  color: rgb(75 85 99);
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  /* padding: 3rem 1.28rem; */
  /* font-family: 'Hurme Geometric Sans 3'; */
  font-family: Tahoma,Geneva,sans-serif
}
/* select option {
  padding: 10px !important;
} */
/* .cancel-option-reason {
  padding: 0.65rem 1.28rem !important;
}
select {
  padding: 0.65rem 1.28rem !important;
} */
 /* .cancel-option-reason select {
    padding: 10px;
  } */
input[type="radio"]:checked {
  background-color: #7838FF;
  padding: 4px;
  border: 5px solid #7838FF;
}
input[type=radio]{
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-clip: content-box;
  border: 2px solid #DCDCF2;
  background-color: #F0F1F7;
}
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
.slider-custom-plan .vue-slider-process {
  background-color: #7838ff;
}
.slider-custom-plan .vue-slider-dot-tooltip-inner {
  background-color: #7838ff;
  border-color: #7838ff;
}

.v-cancel-option-reason .vs__dropdown-option {
  padding: 0.90rem 1.28rem !important;
}
/* custom select */
/* .custom-select {
  position: relative;
  display: inline-block;
}
.select-selected {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.select-items {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 99;
}
.select-items div {
  padding: 10px;
  cursor: pointer;
}
.select-selected:hover ~ .select-items {
  display: block;
} */
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-cancel-subscription {
  width: 90%;
  max-width: 593px !important;
}
</style>