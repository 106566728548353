<template>
    <div class="help-footer">
        <div class="help-footer-xl">
            <feather-icon
                icon="MessageCircleIcon"
                class="mr-1 ml-2 mb-1"
                size="30"
                style="color: #fff;"
            />
            <span class="help-text-footer">Need help?</span>
            <b-button
                variant="primary"
                class="mb-1 ml-1 py-50 help-btn-footer"
                pill
                :to="{ name: 'get-help', params: { url } }"
            >
            Get in touch
            </b-button>
        </div>
        <div class="help-footer-sm">
            <b-link :to="{ name: 'get-help', params: { url } }" style="width: 100%; height: 100%; display: block;">
                <feather-icon
                    icon="MessageCircleIcon"
                    class="mr-1 ml-2 mb-1"
                    size="30"
                    style="color: #fff;"
                />
                <span class="help-text-footer">Need help?</span>
            </b-link>
        </div>
    </div>
    <!--  -->
</template>

<script>
import { BButton, BLink, } from 'bootstrap-vue'

export default {
    components: {
        BButton,
        BLink,
    },
    props: ['url']
}
</script>

<style>
.help-footer {
    background: transparent linear-gradient(96deg, #181E38 0%, #7838FF 100%);
    height: 64px;
    bottom: 0px;
    position: absolute;
    width: 100%;
    padding: 7px !important;
}
.help-text-footer {
    color: #fff !important;
    margin: auto !important;
    font-size: 32px;
    font-weight: 800;
}
.help-btn-footer {
    background: #CA34FF !important;
}
@media (min-width: 500px) {
    .help-footer-sm {
        display: none !important;
    }
}
@media (max-width: 499px) {
    .help-btn-footer {
        display: none !important;
    }
    .help-footer-xl {
        display: none !important;
    }
}
</style>